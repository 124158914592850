
import { Component, Vue } from 'vue-property-decorator'
@Component
export default class PatrolExport extends Vue {
  private searchForm: { fileName: string; startTime: string; endTime: string; status: string } = {
    fileName: '',
    startTime: '',
    endTime: '',
    status: ''
  }

  private stateList = ['生成中', '已生成', '生成失败']
  private loading = false
  private date = []
  private tableData = []

  page = 1
  size = 10
  total = 0

  pickerOptions = {
    disabledDate: (time: Date) => {
      return time.getTime() > Date.now()
    }
  }

  created (): void {
    this.loadData()
  }

  changeDate (date: [string, string]) {
    this.searchForm.startTime = (date && this.$dayjs(date[0]).format('YYYY-MM-DD')) || ''
    this.searchForm.endTime = (date && this.$dayjs(date[1]).format('YYYY-MM-DD')) || ''
  }

  loadData () {
    this.$axios.get(this.$apis.patrol.selectExportByList, {
      size: this.size,
      page: this.page,
      ...this.searchForm
    }).then(res => {
      this.total = res.total || 0
      this.tableData = res.list || []
    })
  }

  toDownload (url: string) {
    window.open(url)
  }

  onDelete (id: string) {
    this.$confirm('确认删除该记录, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.patrol.deleteExport, {
        id
      }).then(() => {
        // console.log('删除成功id', id)
        this.loadData()
      })
    })
  }
}
